#all-callouts-container {
  border:2px solid rgba(119, 119, 119, 0.575);
  border-radius: 8px;
  margin: 20px 3%;
  padding: 1% 3%;
  top: 30px;
  background: url(https://storage.googleapis.com/callouts_tf_resources/public_images/Background%20images/1st%20hexagon%20iteration.jpg) no-repeat, 
  url(https://storage.googleapis.com/callouts_tf_resources/public_images/Background%20images/2nd%20iteration%2C%20repeat.jpg);
  background-size: contain;
  position: relative;
}

#all-callouts-container-empty {
  border:2px solid rgba(119, 119, 119, 0.575);
  border-radius: 8px;
  margin: 20px 3%;
  padding: 1% 3%;
  top: 30px;
  background: url(https://storage.googleapis.com/callouts_tf_resources/public_images/Background%20images/2nd%20iteration%2C%20repeat.jpg);
  background-size: contain;
  position: relative;
}

.showed-maps-indicator {
  color: rgb(223, 223, 223);
  text-align: center;
  padding: 0 0 4px 0;
}