#home-legend {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
  margin: 2% 10%;
  padding: 0px;
  position: relative;
  top: 36px;
  z-index: 4;
}

#legend-card-header__container {
  background-color: rgba(0, 0, 0, 0.582);
}

.legend-title {
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-family: Cambria, Cochin, Verdana, Geneva, Tahoma, sans-serif;
  font-size: 2.2rem;
}

.legend-card-header__click-to-expand {
  margin: 0;
  font-size: 0.8rem;
  color: #999999;
}

.text-center {
  margin: 0;
  padding: 0;
}

.card-body {
  background-image: linear-gradient(rgb(66, 66, 66), rgb(43, 43, 43));
  padding: 0 10%;
}

.legend-element {
  padding: 0;
  margin: 2px 0px;
}

.legend-image {
  height: 4.2rem;
  margin: 1px 0;
}

.equal-sign {
  position: relative;
  top: 8%;
}

.legend-description {
  color: #fff;
  font-size: 1.2rem;
  position: relative;
  top: 20%;
  margin: 0px;
  padding: 0px;
}

.legend-description-light {
  color: #ffffffd7;
}

.legend-hr {
  width: 80%;
  margin: 0;
  padding: 2px 0 0;
  color: rgba(255, 255, 255, 0.774);
}

@media (max-width: 1000px) {
  .legend-description {
    top: 15%;
    font-size: 1.1rem;
  }

  .legend-image {
    height: 3.8rem;
    margin: 1px 0;
  }

}

@media (max-width: 800px) {
  .legend-description {
    top: 10%;
    font-size: 1rem;
  }

  .legend-image {
    height: 3.5rem;
    margin: 1px 0;
  }

}

