@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  padding: 0;
  margin: 0;
  background: url(https://storage.googleapis.com/callouts_tf_resources/public_images/Background%20images/body%20bs%201000%20x%208K%20modest%20shadow.png) no-repeat,
  url(https://storage.googleapis.com/callouts_tf_resources/public_images/Background%20images/concrete%20hex%20background%20very%20dark.jpg);
}

main {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 0px;
}

footer {
  background-color: gray;
  text-align: center;
  margin-top: 20vh;
  padding: 8px;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-control:focus {
  border-color: #FF0000;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(255, 187, 0);
}

.noPaddingMargin{
  margin: 0;
  padding: 0;
}

.display{
  display: block;
}

.hide{
  display: none;
}