#filters-area {
  margin: 0 0 8px;
}

#search-box {
  color-scheme: dark;
  background-color: rgba(31, 31, 31, 0.726);
  border-color: rgb(197, 197, 197);
  width: 100%;
}

::placeholder {
  color: rgb(235, 235, 235);
}

.custom-select {
  background-color: grey;
  border-color: rgba(20, 20, 20, 0.486);
  border-width: 1px;
  color: white;
  font-size: 1rem;
  padding: 6px 12px;
  height: 100%;
  margin: 0;
}

.custom-select:hover {
  background-color: rgb(92, 92, 92);
}
