.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.logo_container {
  background-color: red;
  height: 100%;
  left: 50%;
  margin: 0;
  position: relative;
  padding: 0;
  top: 50%;
  z-index: 5;
}

.main-navigation__logo {
  background-color: linear-gradient(rgb(10, 15, 60), rgb(12, 13, 24));
  left: 50%;
  position: absolute;
  padding: 0;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 44px;
  z-index: 5;
}