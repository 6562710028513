/* GENERAL */

.nav-links {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  width: 100%;
}

.nav-links a {
  border: 1px solid transparent;
  color: #dadada;
  text-decoration: none;
  margin: 0 12px;
  padding: 0 3px;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  color: rgb(255, 145, 0);
  margin: 0 12px;
  padding: 0 3px;
}

.icon-col {
  height: 33px;
}

/* CONTENTS */

.icon {
  font-size: 1.8rem;
  margin: 0;
  top: 20px;
}

.nav_subtitle {
  font-size: 0.7rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding: 4px 0 0;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* SIZE CHANGING */

@media (max-width: 768px) {
  .nav-links a {
    margin: 0 3px;
    padding: 0 1px;
  }

  .nav-links a:hover,
  .nav-links a:active,
  .nav-links a.active {
    color: rgb(255, 145, 0);
    margin: 0 3px;
    padding: 0 1px;
  }

  .subtitle {
    font-size: 0.8rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
}