.login-area {
  color: white;
  position: relative;
  margin: 0;
  padding: 0x;
  width: 50%;
}

.steam-button {
  width: 190px;
  height: 35px;
}

@media (max-width: 768px) {
  .steam-button {
    width: 160px;
    height: 30px;
  }
}

#logout-button{
  font-size: 0.8rem;
  position: relative;
  padding: 0px 2px;
}

#logout-text {
  font-size: 0.7rem;
  margin: 0;
  text-align: right;
}

#username-container a {
  color: #dadada;
  text-decoration: none;
}

#username-container a:hover {
  color: #ce8d3f;
  text-decoration: none;
}

#avatar-image {
  border: 1px solid rgb(135, 135, 135);
  margin: 0px;
  position: relative;
  right: 0px;
  top: 0px;
  width: 46px;
}

#username {
  font-size: 1.3rem;
  margin: 0;
  padding:0px;
  text-align: right;
}

@media (max-width: 991px) {
  #username {
    font-size: 0.9rem;
    line-height: 18px;
    overflow-wrap: break-word;
  }

}

@media (max-width: 576px) {
  #username {
    font-size: 0.75rem;
  }
  #username-container {
    padding: 0px;
  }
}

#fake-steam-login-button{
  background-color: none;
}