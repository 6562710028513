#footer {
  align-items: center;
  background: linear-gradient(rgb(60, 60, 60) 0%,
      rgb(42, 42, 42) 25%,
      rgb(36, 36, 36) 50%,
      rgb(30, 30, 30) 75%,
      rgb(22, 22, 22) 100%);
  color: rgb(144, 144, 144);
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 300;
  position: relative;
  margin-top: 220px;
  padding: 0;
}

#footer a {
  color: rgb(216, 216, 216);
  text-decoration: none;
}

#footer a:hover {
  color: orange;
}

#footer_calloutstf_section {
  height: 100px;
  padding: 0.5%;
}

/* PARTNERS SECTION */

#footer_partners_section {
  color: rgb(255, 255, 255);
  margin: 40px 0 15px;
  text-align: center;
}

#partners-title{
  background-color: rgb(36, 36, 35);
}

#partners{
  margin: 2% 1.5%;
}

.partner_banner {
  width: 85%;
}

.partner_subtitle{
  color: rgb(169, 169, 169);
  font-size: 0.9rem;
}

.secondary_ambassadors {
  background-color: rgba(255, 217, 0, 0.05);
  font-size: 0.9rem;
  font-weight: 300;
  padding: 10px;
}

#ambassadors-expand-button{
  margin: 2px 4px;
  padding: 1px 3px;
}

#callouts_tf_banner {
  height: 100%;
}

#linksmenu{
  font-size: 1.5rem;
  padding: 30px 5px;
}

#linksmenu a{
  color: lightgray;
}

.linksmenu_link{
  border-left: 1px solid grey;
  border-right: 1px solid grey;
}

#footer_bottom_contents {
  padding: 10px;
}

#footer a {
  text-decoration: none;
}

#powered-by-steam {
  color: rgb(191, 191, 191);
}

#powered-by-steam:hover {
  color: rgb(175, 188, 255);
}

#author-section {
  padding-top: 12px;
}

#java59 {
  color: rgb(202, 132, 0);
  font-weight: 500;
}

#java59:hover {
  color: rgb(255, 221, 0);
}