  .map-item-container {
    align-items: left;
    color: rgba(255, 255, 255, 0.808);
    margin: 0px;
    min-width: 300px;
    max-width: 660px;
  }

  .padded-container{
    padding: 1%;
  }

  .thumbnail {
    border-style: solid;
    border-width: 2px 3px;
    border-color: black;
    min-width: 280px;
    max-width: 100%;
    width: 98%;
  }

  .thumbnail:hover {
    border-color: #fff;
  }

  .thumbnail-overlay-indicators-container {
    left: 3px;
    position: relative;
    top: 2px;
  }

  .thumbnail-overlay-indicators {
    left: auto;
    position: absolute;
    width: 50%;
  }

  .callout-title {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.2rem;
    text-decoration: none;
    color: orange;
    padding: 4px 15px;
  }

  .callout-title:active {
    color: rgb(184, 184, 184);
  }

  .callout-title:visited {
    color: rgb(255, 115, 0);
  }

  .callout-date {
    margin: 8px 8px 8px 0;
    font-size: 95%;
    font-weight: 400;
    color: rgb(175, 175, 175);
  }

  .metadata-row {
    min-width: 300px;
    max-width: 100%;
    padding: 0 0 0 5px;
  }

  .callout-counter-icon {
    padding-right: 0px;
  }

  .liked-map {
    color: lightskyblue;
  }

  .callout-counter-number {
    font-size: 0.9rem;
    padding: 0 0 0 10px;
  }

  @media (max-width: 850px) {
    .callout-counter {
      font-size: 0.8rem;
      padding-left: 12%;
    }
  }