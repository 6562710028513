#title-hero {
  box-shadow: 0 5px 10px rgb(0 0 0);
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  height: 20rem;
  width: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  top: 40px;
  z-index: 4;
  padding: 1px 0 4px;
}

.title-elements {
  text-align: center;
  position: relative;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

#title {
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 4.5rem;
  margin: 0;
}

#subtitle {
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  font-style: italic;
}

#megaphone {
  padding: 0 0 15px 15px;
  width: 90px;
}

.map-count-subtitle {
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.4);
  position: relative;
  top: 170px;
}