.main-header {
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background: #4e4e4e;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0;
  z-index: 5;
}

main {
  margin-top: 5rem;
}