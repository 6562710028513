.empty_container {
    justify-content: center;
    padding: 1.5%;
    text-align: center;
}

.empty {
    left: 50%;
    width: 180px;
}

.empty_list_fill{
    color: lightgray;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
}